<template>
  <div id="app" data-app>
    <v-app>
      <v-app-bar dark dense app>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-btn icon>
          <template v-for="(val, lang) in supportedLanguagesMapping">
            <button
              :key="lang"
              v-if="!isCurrentLanguage(lang)"
              v-on:click="btnChangeLang"
              :value="lang"
              class="LangBtn"
              style="border: none; background: none; color: white;"
            >
              {{ val }}
            </button>
          </template>
        </v-btn>
      </v-app-bar>
      <v-navigation-drawer
        app
        v-model="drawer"
        temporary
        v-if="displayNarbar"
        style="text-align: left;"
      >
        <v-list>
          <v-list-item class="px-2">
            <v-list-item-avatar class="ml-93px">
              <v-img :src="require('@/assets/logo.png')"></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ $store.state.userInfo.userName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $store.state.userInfo.userEmail }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
      </v-navigation-drawer>
      <v-main>
        <router-view></router-view>
      </v-main>
      <v-footer padless>
        <v-col class="text-center bg-gray-800 text-light-600 text-xs" cols="12">
          <span class="m-2 w-full">
            &copy; {{ records.year }} {{ $t('main.copyright') }}
          </span>
        </v-col>
      </v-footer>
    </v-app>
    <!-- <v-app-bar color="deep-purple" dark clipped-left>
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title to="/">WINT</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer stateless value="true" mini-variant.sync="mini" absolute clipped app style='top: 64px;'>
      <v-list>
        <v-list-tile>
          <v-list-tile-title>Home</v-list-tile-title>
        </v-list-tile>
        <v-list-group >
          <v-list-tile slot="activator">
            <v-list-tile-title>Accounting</v-list-tile-title>
          </v-list-tile>
          <v-list-group sub-group no-action value="true">
            <v-list-tile slot="activator">
              <v-list-tile-title>Financial Reporting</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :to="$i18nRoute({ name: 'BalanceSheet' })">
              <v-list-tile-title :to="$i18nRoute({ name: 'BalanceSheet' })">{{
                $t("main.balanceSheet")
              }}</v-list-tile-title>
              <v-list-tile-action>
                <v-icon></v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
      </v-list>
    </v-navigation-drawer> -->
    <!-- <router-view
      style="width: 100%; position: absolute; top: 64px; bottom: 38px; overflow: auto;"
    ></router-view> -->
    <!-- Footer -->
    <!-- <div class="footer">
      <p style="margin: 10px;">
        &copy; {{ records.year }} {{ $t("main.copyright") }}
      </p>
    </div> -->
  </div>
</template>

<script>
import { Trans } from '@/plugins/Translation'
import Vuetify from 'vuetify'
import VueCookies from 'vue-cookies'
import moment from 'moment'

export default {
  vuetify: new Vuetify(),
  name: 'app',
  data: function() {
    return {
      records: {
        year: new Date().getFullYear()
      },
      displayNarbar: true,
      drawer: false
    }
  },
  mounted: function() {
    const tokenExp =
      VueCookies.get('wintWebsiteTokenExp') ||
      localStorage.getItem('wintWebsiteTokenExp')
    if (!tokenExp || moment() < tokenExp) {
      this.displayNarbar = false
    }
  },
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages
    },
    currentLanguage() {
      return Trans.currentLanguage
    },
    supportedLanguagesMapping() {
      return Trans.supportedLanguagesMapping
    }
  },
  methods: {
    changeLanguage(e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })

      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage
    },
    btnChangeLang(e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    }
  }
}
</script>

<style>
#app {
  background: url('./assets/minisite_background.png') no-repeat fixed;
  background-size: cover;
  min-height: 700px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #2c2c2c;
  color: #dedcdc;
  font-size: 12px;
}

::-webkit-scrollbar {
  display: none;
}
</style>
