// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import moment from 'moment'
// Vuetify
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Vuetify)

// i18n
import { i18n } from '@/plugins/i18n'
import { Trans } from './plugins/Translation'
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// Moment
Vue.use(require('vue-moment'))

// Vue Cookies
import VueCookies from 'vue-cookies'

import './registerServiceWorker'
Vue.use(VueCookies)

// Set this to false to prevent the production tip on Vue startup.
Vue.config.productionTip = false

// Import Vuex
import store from './store'

import 'windi.css'

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  created: function() {
    const tokenExp =
      VueCookies.get('wintWebsiteTokenExp') ||
      localStorage.getItem('wintWebsiteTokenExp')
    if (tokenExp || moment() >= tokenExp) {
      // this.$store.dispatch('loadPosts')
    }
  },
  render: h => h(App)
})
