import axios from 'axios'
import { BASE_API_PATH } from '@/utils/constant'
import VueCookies from 'vue-cookies'

axios.defaults.headers.common['Authorization'] =
  'Bearer ' +
  (VueCookies.get('wintWebsiteToken') ||
    localStorage.getItem('wintWebsiteToken'))

const api = axios.create({
  baseURL: BASE_API_PATH,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  }
})

api.defaults.timeout = 10000
api.interceptors.request.use(
  config => {
    const token =
      VueCookies.get('wintWebsiteToken') ||
      localStorage.getItem('wintWebsiteToken')
    config.headers.common['Authorization'] = 'Bearer ' + token
    config.method = 'POST'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      // If error code 999 = Unauthorized
      let errorCode = response.data.errorCode || ''
      if (errorCode && errorCode != 200) {
        window.location = './#/en/login'
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      return Promise.reject(error.response)
    }
  }
)

export default api
