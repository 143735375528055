import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import request from '@/utils/request'
import VueCookies from 'vue-cookies'

const store = new Vuex.Store({
  state: {
    accountCodeList: [],
    customerList: [],
    companyNameEn: '',
    userInfo: {
      userName: '',
      userEmail: ''
    },
    financialPeriod: []
  },
  actions: {
    async loadPosts({ commit }) {
      const { data } = await request({
        url: '/?t=loadMaster',
        data: {}
      })

      const statusCode = data.statusCode
      if (statusCode == 200) {
        commit('updateAccountCodeList', data.accountCodelist)
        commit('updateCustomerList', data.customerList)
        commit('updateFinancialPeriod', data.financialPeriod)
      }

      // Get Company Info from the token
      const token =
        VueCookies.get('wintWebsiteToken') ||
        localStorage.getItem('wintWebsiteToken')
      try {
        const payloadBase64 = token.split('.')[1] ?? ''
        if (payloadBase64) {
          let payload = atob(payloadBase64)
          payload = JSON.parse(payload)
          const companyNameEn = payload.data.companyNameEn ?? ''
          commit('updateCompanyInfo', companyNameEn)

          commit('updateUserInfo', payload.data)
        }
      } catch (err) {
        this.state.errorMsg = err
      }
    }
  },
  mutations: {
    updateAccountCodeList(state, data) {
      state.accountCodeList = data
    },
    updateCustomerList(state, data) {
      state.customerList = data
    },
    updateFinancialPeriod(state, data) {
      state.financialPeriod = data
    },
    updateCompanyInfo(state, data) {
      state.companyNameEn = data
    },
    updateUserInfo(state, data) {
      state.userInfo.userName = data.name ?? ''
      state.userInfo.userEmail = data.email ?? ''
    }
  }
})

export default store
