/* eslint-disable no-unused-vars */
import { Trans } from '@/plugins/Translation'
// import axios from "axios";
// import { BASE_API_PATH } from "@/utils/constant";
// import request from "@/utils/request";
import VueCookies from 'vue-cookies'
import moment from 'moment'

function load(component) {
  // '@' is aliased to src/components
  return () =>
    import(/* webpackChunkName: "[request]" */ `@/pages/${component}.vue`)
}

const ifNotAuthenticated = (to, from, next) => {
  // var loggedIn = "";
  // Get Token from indexedDB
  // var request = window.indexedDB.open("winthk");
  // request.onupgradeneeded = event => {
  //   var db = event.target.result;
  //   if (!db.objectStoreNames.contains("sessions")) {
  //     db.createObjectStore("sessions", {
  //       autoIncrement: true
  //     });
  //   }
  // };
  // request.onsuccess = () => {
  //   var db = request.result;
  //   if (!db.objectStoreNames.contains("sessions")) {
  //     next("/en/Login");
  //     return;
  //   }
  //   var transaction = db.transaction(["sessions"], "readwrite");
  //   var objectStore = transaction.objectStore("sessions");
  //   var records = objectStore.getAll();
  //   records.onsuccess = e => {
  //     if (e.target.result.length) {
  //       loggedIn = e.target.result[0].authToken
  //         ? e.target.result[0].authToken.token
  //         : "";
  //     }

  //     // If no auth token, then back to login page
  //     if (to.name == "Login" || !loggedIn) {
  //       next("/en/Login");
  //       return;
  //     }
  //     var isActive = false;
  //     const access_token = {
  //       headers: { Authorization: `Bearer ${loggedIn}` }
  //     };
  //     axios
  //       .post(
  //         BASE_API_PATH + "/?t=loginSession",
  //         // "//localhost:8080/loginSession",
  //         JSON.stringify({ data: "" }),
  //         access_token
  //       )
  //       .then(response => {
  //         isActive = response.data.statusCode == 200 ? true : false;
  //         if (!isActive) {
  //           next("/en/Login");
  //         } else {
  //           next();
  //         }
  //       })
  //       .catch(e => {
  //         next("/en/Login");
  //       });
  //   };
  // };

  const tokenExp =
    VueCookies.get('wintWebsiteTokenExp') ||
    localStorage.getItem('wintWebsiteTokenExp')
  if (!tokenExp || moment() < tokenExp) {
    next('/en/Login')
  } else {
    next()
  }
  // var isActive = false;
  // request({
  //   url: "/?t=loginSession",
  //   data: "",
  // })
  //   .then((response) => {
  //     isActive = response.data.statusCode == 200 ? true : false;
  //     if (!isActive) {
  //       next("/en/Login");
  //     } else {
  //       next();
  //     }
  //   })
  //   .catch((e) => {
  //     next("/en/Login");
  //   });
}

export default [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>'
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: load('Home'),
        beforeEnter: ifNotAuthenticated
      },
      {
        path: 'Login',
        name: 'Login',
        component: load('Login')
      },
      // End of Basic Settings
      {
        path: '*',
        component: load('404'),
        beforeEnter: ifNotAuthenticated
      }
    ]
  },
  {
    // Redirect user to supported lang version.
    path: '*',
    redirect(to) {
      return Trans.getUserSupportedLang()
    }
  }
]
